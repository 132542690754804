.App {
  text-align: center;
}

.App-header {
  background-color: #b7b7b7;
  min-height: 3vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.my_class {
  transform: rotate(90deg);
}




#sidebar nav {
  flex: 1;
  overflow: auto;
  /* padding-top: 1rem; */
}

#sidebar nav a span {
  float: right;
  color: #eeb004;
}
#sidebar nav a.active span {
  color: inherit;
}

i {
  color: #818181;
}
#sidebar nav .active i {
  color: inherit;
}

#sidebar ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

#sidebar li {
  margin: 0.25rem 0;
  background-color: #fff;
  border-radius: 8px;
}

#sidebar nav a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;

  white-space: pre;
  padding: 0.5rem;
  border-radius: 8px;
  color: inherit;
  text-decoration: none;
  gap: 1rem;
}

#sidebar nav a:hover {
  background: #e3e3e3;
}

#sidebar nav a.active {
  background: #ffd110; /* hsl(224, 98%, 58%); */
/*   color: white; */
  font-family: 'Comfortaa';
  font-size: 14px;
}

#sidebar nav a.pending {
  color: hsl(224, 98%, 58%);
}






#upbar nav {
  flex: 1;
  overflow: auto;
  /* padding-top: 1rem; */
}

#upbar nav a span {
  float: right;
  color: #eeb004;
}
#upbar nav a.active span {
  color: inherit;
}


#upbar nav .active i {
  color: inherit;
}

#upbar ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
}

#upbar li {
  margin: 0.25rem 0;
  background-color: #fff;
  border-radius: 8px;
}

#upbar nav a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;

  white-space: pre;
  padding: 0.5rem;
  border-radius: 8px;
  color: inherit;
  text-decoration: none;
  gap: 1rem;

  font-family: 'Comfortaa';
  font-size: 14px;

}

#upbar nav a:hover {
  background: #e3e3e3;
}

#upbar nav a.active {
  background: #ffd110; /* hsl(224, 98%, 58%); */
/*   color: white; */
  font-family: 'Comfortaa';
  font-size: 14px;
}

#upbar nav a.pending {
  color: hsl(224, 98%, 58%);
}

