.group {
  display: inline-flex;
  border-radius: 8px 0;
  border: 1px solid rgba(0, 0, 0, 0.05);
  padding: 2px;
}

.item {
  display: block;
  position: relative;
  border-radius: 8px;
}

.input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
}

.label {
  border-radius: 8px;
  cursor: pointer;
  padding: 1px 4px;
  box-sizing: border-box;
  /* font-family: "SF Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 15px; */
  line-height: 20px;
}

.item[data-checked="true"] .label {
  /* background-color: rgba(193, 222, 239, 0.5); */
  background-color: rgba(255, 209, 16, 0.9);
  /* #ffd110 */
}

.label:hover,
.item[data-checked="true"] .label:hover {
  /* background-color: rgba(193, 222, 239, 0.9); */
  background-color: rgba(171, 171, 171, 0.9);
}