/* @font-face {
    font-family: Comfortaa;//гарнитура
    src: local("Comfortaa");//проверка
    url: (public/Comfortaa-VariableFont_wght.ttf);//путь 
} */

.navbar,
.navbar-default {
    background-image: linear-gradient(to bottom, #75B53E 0, #75B53E 100%) !important;
}

.navbar a {
    color: #ffffff;
    font-size: 13px;
    font-weight: 600;
    font-family: 'Comfortaa';
    text-transform: uppercase;
    letter-spacing: 1.5px;
    text-decoration: none;
}

.btn,
.btn-default {
    color: #000000;
    background-color: #ffd110;
    border-radius: 30px;
    -moz-border-radius: 30px;
    -webkit-border-radius: 30px;
    border: 0;
}

.navbtn {
    color: #ffffff;
    font-size: 13px;
    font-weight: 600;
    font-family: 'Comfortaa';
    text-transform: uppercase;
    letter-spacing: 1.5px;
    background-color: transparent;
    align-content: flex-end;
}

.navselect {
    color: #ffffff;
    font-size: 13px;
    font-weight: 600;
    font-family: 'Comfortaa';
    text-transform: uppercase;
    letter-spacing: 1.5px;
    background-color: transparent;
    border: 0;
}

.navselect::-ms-expand {
    display: none;
}

.navselect:hover {
    border-color: #888;
}

.navselect:focus {
    border-color: #aaa;
    box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222;
    outline: none;
}

.navselect option {
    font-weight: normal;
    /*} 
/* *[dir="rtl"] .navselect, :root:lang(ar) .navselect, :root:lang(iw) .navselect { 
/* background-position: left .7em top 50%, 0 0; 
padding: .6em .8em .5em 1.4em;  */
}

.admbtn {
    color: #000000;
    background-color: #ffd110;
}

.listgroupitemadm.active {
    color: #000000;
    background-color: #ffd110;
    /*     border-radius: 30px;
    -moz-border-radius: 30px;
    -webkit-border-radius: 30px;*/
    border: 0;
}

.tableaction {
    text-justify: right;
}

.container {
    padding: 0px;
}

.calendar {
    height: 500px;
}

/* 
.tn-atom__img {
    width: 40px;
    height: 40px;
    display: block;
}
 */